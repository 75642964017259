:root {
  --bobbycto-netlify-app-roboto-regular-16-font-family: "Roboto", Helvetica;
  --bobbycto-netlify-app-roboto-regular-16-font-size: 16px;
  --bobbycto-netlify-app-roboto-regular-16-font-style: normal;
  --bobbycto-netlify-app-roboto-regular-16-font-weight: 400;
  --bobbycto-netlify-app-roboto-regular-16-letter-spacing: 0px;
  --bobbycto-netlify-app-roboto-regular-16-line-height: 24px;
  --bobbycto-netlify-app-shadows-into-light-regular-100-font-family: "Shadows Into Light", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-regular-100-font-size: 100px;
  --bobbycto-netlify-app-shadows-into-light-regular-100-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-regular-100-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-regular-100-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-regular-100-line-height: 100px;
  --bobbycto-netlify-app-shadows-into-light-regular-24-28-font-family: "Shadows Into Light", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-regular-24-28-font-size: 24.28125px;
  --bobbycto-netlify-app-shadows-into-light-regular-24-28-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-regular-24-28-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-regular-24-28-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-regular-24-28-line-height: 20px;
  --bobbycto-netlify-app-shadows-into-light-regular-24-font-family: "Shadows Into Light", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-regular-24-font-size: 24px;
  --bobbycto-netlify-app-shadows-into-light-regular-24-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-regular-24-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-regular-24-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-regular-24-line-height: 24px;
  --bobbycto-netlify-app-shadows-into-light-regular-27-23-font-family: "Shadows Into Light", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-regular-27-23-font-size: 27.234375px;
  --bobbycto-netlify-app-shadows-into-light-regular-27-23-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-regular-27-23-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-regular-27-23-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-regular-27-23-line-height: 20px;
  --bobbycto-netlify-app-shadows-into-light-regular-29-37-font-family: "Shadows Into Light", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-regular-29-37-font-size: 29.3671875px;
  --bobbycto-netlify-app-shadows-into-light-regular-29-37-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-regular-29-37-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-regular-29-37-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-regular-29-37-line-height: 20px;
  --bobbycto-netlify-app-shadows-into-light-regular-42-font-family: "Shadows Into Light", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-regular-42-font-size: 42px;
  --bobbycto-netlify-app-shadows-into-light-regular-42-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-regular-42-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-regular-42-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-regular-42-line-height: 42px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-132-89-font-family: "Shadows Into Light Two", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-two-regular-132-89-font-size: 132.890625px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-132-89-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-two-regular-132-89-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-two-regular-132-89-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-132-89-line-height: 140px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-140-font-family: "Shadows Into Light Two", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-two-regular-140-font-size: 140px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-140-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-two-regular-140-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-two-regular-140-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-140-line-height: 140px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-21-94-font-family: "Shadows Into Light Two", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-two-regular-21-94-font-size: 21.9375px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-21-94-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-two-regular-21-94-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-two-regular-21-94-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-21-94-line-height: 24px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-23-44-font-family: "Shadows Into Light Two", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-two-regular-23-44-font-size: 23.4375px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-23-44-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-two-regular-23-44-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-two-regular-23-44-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-23-44-line-height: 24px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-24-font-family: "Shadows Into Light Two", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-two-regular-24-font-size: 24px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-24-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-two-regular-24-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-two-regular-24-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-24-line-height: 24px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-46-68-font-family: "Shadows Into Light Two", Helvetica;
  --bobbycto-netlify-app-shadows-into-light-two-regular-46-68-font-size: 46.6796875px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-46-68-font-style: normal;
  --bobbycto-netlify-app-shadows-into-light-two-regular-46-68-font-weight: 400;
  --bobbycto-netlify-app-shadows-into-light-two-regular-46-68-letter-spacing: 0px;
  --bobbycto-netlify-app-shadows-into-light-two-regular-46-68-line-height: 50px;
  --bobbyctonetlifyappaquamarine-blue: rgba(110, 193, 228, 1);
  --bobbyctonetlifyappblack: rgba(0, 0, 0, 1);
  --bobbyctonetlifyappmine-shaft: rgba(51, 51, 51, 1);
  --bobbyctonetlifyappnero: rgba(255, 255, 255, 1);
  --bobbyctonetlifyappnero-lime: rgba(255, 255, 255, 1);
}
